// Section.js
import React, { useState } from "react";
import "../static/fonts/font.css";
import ImageUrls from "../components/ImageUrls";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import moreButton from "../assets/moreButton.png";
// import closeButton from "../assets/closeButton.svg";

const Portfolio = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1025 });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isImageZoomed, setIsImageZoomed] = useState(false);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
    document.body.classList.add("body-scroll-lock");
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
    setIsModalOpen(false);
    document.body.classList.remove("body-scroll-lock");
  };

  const sectionStyle = {
    width: "100.0000vw",
    height: isTablet ? "66vh" : "100.0000vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F3F5",
  };

  const text1 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "3.1250vw" : "3.1250vw",
    height: isMobile ? "1.8519vh" : isTablet ? "1.8519vh" : "1.8519vh",
    left: isMobile ? "44vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "211vh" : isTablet ? "144vh" : "213.8889vh",
    opacity: "1.00",
    color: "#2A51EC",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.5vw" : "1.0471vw",
    fontWeight: "500",
    textAlign: isMobile ? "center" : "left",
    lineHeight: isMobile ? "4.8438vw" : "1vw",
  };

  const text2 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "" : "24.4792vw",
    height: isMobile ? "3.5938vw" : isTablet ? "6.3889vh" : "6.3889vh",
    left: isMobile ? "19vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "214vh" : isTablet ? "143vh" : "213.8889vh",
    opacity: "1.00",
    fontFamily: "SCoreDream",
    fontSize: isMobile ? "9.3750vw" : isTablet ? "4vw" : "3.1250vw",
    fontWeight: "900",
    textAlign: isMobile ? "center" : "left",
    lineHeight: isMobile ? "4.6875vw" : "8.3333vh",
    color: "#111111",
  };

  const container = {
    position: "absolute",
    width: isMobile ? "100%" : isTablet ? "100%" : "90%",
    height: isMobile ? "60vh" : isTablet ? "35vh" : "31vw",
    left: isMobile ? "" : isTablet ? "" : "",
    top: isMobile ? "229vh" : isTablet ? "156vh" : "232vh",
    overflowX: isMobile ? "" : isTablet ? "scroll" : "scroll",
    overflowY: isMobile ? "scroll" : isTablet ? "" : "",
  };

  const boxStyle = {
    position: "absolute",
    width: isMobile ? "75vw" : isTablet ? "30vw" : "15vw",
    height: isMobile ? "105vw" : isTablet ? "42vw" : "21vw",
    opacity: "1.00",
    borderRadius: "20px",
    backgroundColor: "transparent",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    cursor: "pointer",
  };

  const boxes = [
    {
      ...boxStyle,
      left: isMobile ? "10vw" : isTablet ? "9.3750vw" : "4vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[0]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "90vw" : isTablet ? "42.3750vw" : "22vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[1]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "170vw" : isTablet ? "75.3750vw" : "40vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[2]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "250vw" : isTablet ? "108.3750vw" : "58vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[3]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "330vw" : isTablet ? "141.3750vw" : "76vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[4]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "410vw" : isTablet ? "174.3750vw" : "94vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[5]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "490vw" : isTablet ? "207.3750vw" : "112vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[6]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "570vw" : isTablet ? "240.3750vw" : "130vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[7]})`,
      marginRight: "5vw",
    },
    {
      ...boxStyle,
      left: isMobile ? "650vw" : isTablet ? "273.3750vw" : "148vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[8]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "730vw" : isTablet ? "306.3750vw" : "166vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[9]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "810vw" : isTablet ? "339.3750vw" : "184vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[10]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "890vw" : isTablet ? "372.3750vw" : "202vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[11]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "970vw" : isTablet ? "405.3750vw" : "220vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[12]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "1050vw" : isTablet ? "438.3750vw" : "238vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[13]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "1130vw" : isTablet ? "471.3750vw" : "256vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[14]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "1130vw" : isTablet ? "504.3750vw" : "274vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[15]})`,
    },
    {
      ...boxStyle,
      left: isMobile ? "1210vw" : isTablet ? "537.3750vw" : "292vw",
      top: isMobile ? "5vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: `url(${ImageUrls[16]})`,
    },
    {
      ...boxStyle,
      width: "5vw",
      left: isMobile ? "1290vw" : isTablet ? "570.3750vw" : "310vw",
      top: isMobile ? "2vw" : isTablet ? "1vw" : "3.0468vw",
      backgroundImage: "transparent",
    },
  ];

  const imageSets = [
    [],
    [],
    [{ type: "video", src: "/video/asca.mp4" }],
    [{ type: "video", src: "/video/xinu_video.mp4" }],
    [],
    [{ type: "video", src: "/video/auyaka_video.mp4" }, ImageUrls[42]],
    [
      { type: "video", src: "/video/anthem_june.mp4" },
      { type: "video", src: "/video/anthem_video.mp4" },
    ],
    [{ type: "video", src: "/video/auyaka_april.mp4" }, ImageUrls[41]],
    [{ type: "video", src: "/video/Wanuka_intro.mp4" }],
    [
      { type: "video", src: "/video/macaroniempitsu_intro.mp4" },
      { type: "video", src: "/video/macaroniempitsu.mp4" },
      ImageUrls[32],
      ImageUrls[33],
      ImageUrls[34],
      ImageUrls[35],
      ImageUrls[36],
      ImageUrls[37],
      ImageUrls[38],
      ImageUrls[39],
      ImageUrls[40],
    ],
    [],
    [
      { type: "video", src: "/video/Novelbright_intro.mp4" },
      ImageUrls[23],
      ImageUrls[24],
      ImageUrls[25],
      ImageUrls[26],
      ImageUrls[27],
      ImageUrls[28],
      ImageUrls[29],
      ImageUrls[30],
      ImageUrls[31],
    ],
    [
      { type: "video", src: "/video/burnoutsyndrome_8월.mp4" },
      // { type: "video", src: "/video/burnoutsyndrome2_8월.mp4" },
      ImageUrls[20],
    ],
    [{ type: "video", src: "/video/anthem_7월.mp4" }, ImageUrls[20]],
    [{ type: "video", src: "/video/auyaka_5월.mp4" }, ImageUrls[19]],
    [{ type: "video", src: "/video/regretgirl_4월.mp4" }],
    [{ type: "video", src: "/video/lucklife_3월.mp4" }, ImageUrls[18]],
  ];

  const modalImageStyle = {
    maxWidth: isMobile ? "90vw" : isTablet ? "50vw" : "100vw", // Add this to ensure the image doesn't exceed the container's width
    marginTop: isMobile ? "5vh" : "",
    zIndex: 1,
  };

  const modalSubImageStyle = {
    width: isMobile ? "90vw" : "45vw",
    // height: "20vw",
  };

  const buttonStyle = {
    position: "absolute",
    width: isMobile ? "20vw" : isTablet ? "9vw" : "6.2500vw",
    height: isMobile ? "10vw" : isTablet ? "4.5vw" : "2.5vw",
    left: isMobile ? "40vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "224vh" : isTablet ? "152.5vh" : "229vh",
    backgroundImage: "linear-gradient(to right, #f9ce34, #ee2a7b, #6228d7)",
    color: "white",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: isMobile ? "700" : isTablet ? "700" : "500",
    border: "none",
    borderRadius: isMobile ? "4vw" : isTablet ? "1.3021vw" : "1.3021vw",
    cursor: "pointer",
  };

  const buttonStyle2 = {
    position: isMobile ? "relative" : isTablet ? "relative" : "relative",
    width: isMobile ? "50vw" : isTablet ? "20vw" : "10vw",
    height: isMobile ? "10vw" : isTablet ? "4vw" : "2vw",
    left: isMobile ? "12.5vw" : isTablet ? "5vw" : "2.5vw",
    top: isMobile ? "107vw" : isTablet ? "43vw" : "22vw",
    backgroundColor: "#F2F3F5",
    color: "#111111",
    borderRadius: isMobile ? "4vw" : isTablet ? "1.3021vw" : "1.3021vw",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: isMobile ? "700" : isTablet ? "700" : "500",
    fontStyle: "normal",
    border: "solid",
    cursor: "pointer",
  };

  const pageGroup = {
    position: "absolute",
    width: isMobile ? "18vw" : "5.7292vw",
    height: isMobile ? "18vw" : "5.7292vw",
    left: isMobile ? "80vw" : "92.1875vw",
    top: isMobile ? "290vh" : isTablet ? "192vh" : "285vh",
    backgroundColor: "transparent",
  };

  const page1 = {
    position: "absolute",
    width: isMobile ? "3.5417vw" : "3.5417vw",
    height: isMobile ? "3.5938vw" : "6.2963vh",
    top: isMobile ? "-3.5vh" : "-4.1667vw",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "9.3750vw" : "3.1250vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#111111",
  };

  const page2 = {
    position: "absolute",
    width: isMobile ? "2.3958vw" : "3.5417vw",
    height: isMobile ? "2.4479vw" : "6.2963vh",
    left: isMobile ? "9vw" : "2.4vw",
    top: isMobile ? "3vh" : "0vh",
    opacity: "0.20",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "6.2500vw" : "2.0833vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#111111",
  };

  const divider = {
    position: "absolute",
    width: isMobile ? "10vw" : "2.6042vw",
    height: isMobile ? "0.1042vw" : "0.1042vw",
    left: isMobile ? "6vw" : "2vw",
    top: isMobile ? "10vw" : "3vw",
    opacity: "1.00",
    backgroundColor: "#111111",
    transform: "rotate(-45deg)",
  };

  return (
    <section id={id} style={sectionStyle}>
      <div>
        <p style={text1}>Portfolio</p>
      </div>
      <div>
        <p style={text2}>Work Gallery</p>
      </div>
      <a
        href="https://www.instagram.com/ruienm_kor/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <img src={moreButton} style={buttonStyle} /> */}
        <button style={buttonStyle}>Instagram</button>
      </a>
      <div style={container}>
        {boxes.map((box, index) => (
          <div
            key={index}
            style={box} // Use the styles defined in the 'boxes' array
            onClick={() => {
              if (index !== boxes.length - 1) {
                openModal(index);
              }
            }}
            className="image-hover"
          >
            {index !== boxes.length - 1 && (
              <button
                style={buttonStyle2} // Customize the button style as needed
                onClick={() => openModal(index)}
              >
                Details
              </button>
            )}
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-image-container">
              <img
                src={ImageUrls[selectedImageIndex]}
                alt="Modal Image"
                className="modal-image"
                style={modalImageStyle}
              />
            </div>
            <div
              style={{
                width: "90%",
                textAlign: "center",
                borderBottom: isMobile
                  ? "0.2604vw solid #aaa"
                  : isTablet
                  ? "0.2604vw solid #aaa"
                  : "0.1302vw solid #aaa",
                lineHeight: "0.1em",
                margin: isMobile
                  ? "8vw 0vw 8vw"
                  : isTablet
                  ? "5vw 0vw 5vw"
                  : "3vw 0vw 2vw",
              }}
            >
              <span
                style={{
                  position: "relative",
                  top: isMobile ? "1.5vw" : isTablet ? "1vw" : "0.2604vw",
                  background: "#F2F3F5",
                  padding: "1.0417vw 0.5208vw",
                  fontFamily: "Pretendard Variable",
                  fontWeight: "700",
                  fontSize: isMobile ? "6vw" : isTablet ? "4vw" : "1.3021vw",
                }}
              >
                Photo and Video
              </span>
            </div>
            <div className="modal-explanation">
              <div className="image-set-container">
                {imageSets[selectedImageIndex].map((media, index) => (
                  <div key={index}>
                    {media.type === "video" ? (
                      <video controls loop width="90%" height="auto">
                        <source src={media.src} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={media}
                        alt={`Modal Image ${index}`}
                        className="modal-subImage"
                        style={modalSubImageStyle}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="close-button" onClick={closeModal}>
              <svg
                width="60px"
                height="60px"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#000000"
              >
                <line x1="16" y1="16" x2="48" y2="48" />
                <line x1="48" y1="16" x2="16" y2="48" />
              </svg>
            </div>
          </div>
        </div>
      )}

      <div style={pageGroup}>
        <div>
          <p style={page1}>03</p>
        </div>
        <div>
          <p style={page2}>06</p>
        </div>
        <div style={divider}></div>
      </div>
    </section>
  );
};

export default Portfolio;
